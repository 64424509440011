
.buttonIcon{
  display: inline-block;
  vertical-align: middle;
  width:14px;
  margin-right:10px;
}

.squareButtonContainer{
  display:inline-block;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-self: center;
  vertical-align: middle;
  border-radius: 10px;
  padding:8px;
  background:linear-gradient(0deg,#3056ff,#5a00ff);
  border:2px solid #5a00ff;
  height:16px;
}
.squareButtonContainer:hover{
  cursor:pointer;
  opacity:0.6;
}

.plusIcon{
  width:16px;
}

.gridLoader{
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
}

.buttonContainer{
  margin-top: 20px;
  display:inline-block;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.buttonLabel{
  position: relative;
  left:35%;
  transform: translate(-50%, 0%);
  text-align: center;
  align-items: center;
  justify-content: center;
  align-self: center;
  background:red;
  width:200px;
}

.buttonName{
  color:#989898;
  margin-top: 10px;
  font-family: 'Nunito', sans-serif;
}

.buttonInputStyled{
  width:200px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.buttonInputStyled:hover{
  cursor:pointer;
  opacity:0.7;
}

.buttonInputIconSection{
  flex:1;
  background:#6026ff;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding:6px;
}

.buttonInputIcon{
  margin-top: 2px;
  width:16px;
}


.buttonInputTextSection{
  font-size:1rem;
  background:#6026ff;
  color:white;
  text-align: center;
  padding:6px;
  font-family: 'Nunito', sans-serif;
  justify-content: center;
  vertical-align: middle;
  flex:3;
}
