@font-face {
  font-family: GothamPro;
  src: url("GothamPro-Medium.ttf");
}

@font-face {
  font-family: GothamProBold;
  src: url("GothamPro-Bold.ttf");
}

.bp3-tabs{
  display: inline-block;
  vertical-align: middle;
  font-family: "Comfortaa", cursive;
  width:100%;
  padding:20px;
  height:90%;
}

.bp3-tab{
  color:#6F87FF;
  padding-left: 4px;
  overflow-y:auto;
  height:100%;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #989898;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #989898;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #989898;
}

.global-loading-page{
  width:100%;
  height:100%;
  text-align: center;
  background: #f5f6ff;
  position: fixed;
  top:0px;
  left:0px;
}

.global-loader{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
}

.global-container{
  width:100%;
  height:100%;
  text-align: center;
  background: #f5f6ff;
  position: fixed;
  top:0px;
  left:0px;
}

.global-card-view{
  background: #fff;
  border-radius:24px;
  box-shadow: 0px 5px 12px rgba(10,0,180,0.1);
  padding:30px;
}

.global-login-card-view{
  background: #fff;
  border-radius:24px;
  box-shadow: 0px 5px 12px rgba(10,0,180,0.1);
  padding:10px;
  min-width:550px;
}


.global-mobile-logo{
  margin-bottom: 8vh;
  width:60vw;
}

.global-mobile-popup{
  font-size: 1.4rem;
  padding:2vh;
  text-align: center;
  color:#5d00d6;
  font-family: "Comfortaa", cursive;
  width:80vw;
}

.global-header{
  width:100%;
  background:white;
  padding-top:1.3vh;
  padding-bottom:1.3vh;
  display: flex;
}

.global-header-profile{
  display:inline-block;
  text-align: left;
  margin-left: 2vw;
  padding:0.4vh;
  padding-left: 1.2vw;
  padding-right:1.2vw;
  border-radius: 100px;
  background:#5199ff;
}

.global-header-profile-image{
  width:3vh;
  border-radius:10px;
  height:3vh;
  display: inline-block;
  vertical-align: middle;
  margin-top: 1px;
  background:white;
}

.global-header-profile-text{
  color:white;
  font-size: 0.9rem;
  font-family: GothamPro;
  font-weight: normal;
  display: inline-block;
  vertical-align: middle;
  margin-left: 1vw;
  margin-top: 3px;
}

.global-header-buttons{
  flex:1;
  display:inline-block;
  text-align: right;
  vertical-align: middle;
  margin-right:30px;
}

@keyframes headerButtonTilt {
  from {transform:rotate(0deg);}
  to {transform:rotate(20deg);}
}


.global-header-button{
  display: inline-block;
  vertical-align: middle;
  width:24px;
  margin-right: 32px;
  margin-top: 2px;
  opacity:0.9;
}

.global-header-button:hover{
  cursor:pointer;
  opacity:0.7;
  animation-name: headerButtonTilt;
  animation-duration: 0.4s;
  transform:rotate(20deg);
}

.global-dashboard-row{
  width:100%;
  margin-top: 20px;
  min-height:22vh;
  display: flex;
  flex-direction: row;
}

.global-dashboard-row-large{
  width:100%;
  margin-top: 20px;
  margin-bottom:10px;
  min-height:52vh;
  display: flex;
  flex-direction: row;
}

.global-dashboard-row-small{
  width:100%;
  margin-top: 20px;
  margin-bottom:10px;
  min-height:16vh;
  display: flex;
  flex-direction: row;
}

.global-dashboard-row-card{
  background:white;
  box-shadow: 0px 2px 7px #0000B422;
  display:flex;
  flex-direction: row;
  flex:1;
  margin:10px;
  border-radius:12px;
  height:100%;
  overflow-y: hidden;
  position: relative;
}

.card-flex-column{
  flex-direction: column;
}

.global-dashboard-row-card-wide{
  background:white;
  box-shadow: 0px 2px 7px #0000B422;
  display:flex;
  flex:2;
  margin:10px;
  border-radius:12px;
  height:100%;
  overflow-y: hidden;
  position: relative;
}

.global-card-border-pink{
  position: absolute;
  top:10%;
  left:0px;
  height:80%;
  width:4px;
  background:#E90062;
  border-top-right-radius:100px;
  border-bottom-right-radius:100px;
  opacity:1.0;
}

.global-card-border-purple{
  position: absolute;
  top:10%;
  left:0px;
  height:80%;
  width:4px;
  background:linear-gradient(0deg,#6F00E9,#6F87FF);
  border-top-right-radius:100px;
  border-bottom-right-radius:100px;
  opacity:1.0;
}

.global-card-border-light-purple{
  position: absolute;
  top:10%;
  left:0px;
  height:80%;
  width:4px;
  background:linear-gradient(0deg,#6F00E9,#6F87FF);
  border-top-right-radius:100px;
  border-bottom-right-radius:100px;
  opacity:1.0;
}

.global-card-border-blue{
  position: absolute;
  top:10%;
  left:0px;
  height:80%;
  width:4px;
  background:linear-gradient(0deg,#3300D4,#13B1FF);
  border-top-right-radius:100px;
  border-bottom-right-radius:100px;
  opacity:1.0;
}

.global-card-border-light-blue{
  position: absolute;
  top:10%;
  left:0px;
  height:80%;
  width:4px;
  background:linear-gradient(0deg,#3300D4,#13B1FF);
  border-top-right-radius:100px;
  border-bottom-right-radius:100px;
  opacity:1.0;
}

.global-card-border-blue-right{
  position: absolute;
  top:10%;
  right:0px;
  height:80%;
  width:4px;
  background:linear-gradient(0deg,#3300D4,#13B1FF);
  border-top-left-radius:100px;
  border-bottom-left-radius:100px;
  opacity:1.0;
}

.logistible-tab{
  color: #8080da;
}

.bp3-tab-indicator{
  background-color: #8080da;
}

.global-dashboard-row-card-header{
  background:#5f59ff;
  padding:5px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
}

.global-card-header-title{
  color:white;
  font-family: "Comfortaa", cursive;
  font-size: 0.8rem;
  text-align:left;
}

.global-card-header-menu-container{
  flex:1;
  text-align: right;
  justify-content: flex-end;
}

.global-card-menu-icon{
  height:12px;
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
}

.global-card-menu-icon:hover{
  cursor:pointer;
}

.global-header-search-bar{
  display: inline-block;
  vertical-align: middle;
  margin-left: 14vw;
  border-radius: 6px;
  padding:10px;
  padding-top: 7px;
  padding-bottom:7px;
  background:#adcdff68;
  width:30vw;
  display:flex;
}

.global-header-search-icon{
  height:16px;
  margin-top: 1px;
  margin-right: 10px;
  opacity: 0.9;
}

.global-header-search-input{
  flex:1;
  font-size: 0.9rem;
  color:#333333;
  outline: none;
  border:0px;
  font-family: "Comfortaa", cursive;
  background:#00000000;
}

.global-header-search-input:focus{
  outline:none;
}

.paragraph-header{
  font-size:1.3rem;
  color:#2e51ff;
}

.signup-button-blue{
  display: inline-block;
  margin-top: 30px;
  vertical-align: middle;
  padding:8px;
  padding-left: 32px;
  padding-right:32px;
  border-radius: 100px;
  color:white;
  background: #2e51ff;
  font-size: 1rem;
  font-family: "Comfortaa", sans-serif;
}
.signup-button-blue:hover{
  cursor: pointer;
  opacity:0.8;
  background:#32e6d7;
}

.signup-button-blue-icon{
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width:18px;
  margin-top: -3px;
}
