@font-face {
  font-family: GothamPro;
  src: url("../../styles/GothamPro-Medium.ttf");
}


.noOrdersContainer{
  margin-top: 16vh;
  color:#ABABAB;
  font-size: 1.1rem;
  font-family: "Nunito", sans-serif;
}

.globalContainer{
  width:100%;
  height:100%;
  position: absolute;
  top:0px;
  left:0px;
}
.headerButtonBlueMenu{
  display:none;
}

.header{
  width:100%;
  top:0px;
  left:0px;
  padding-top:20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  z-index:30;
  background: white;
  border-bottom: 1px solid #BCBCBC;
}

.headerLogo{
  margin-left: 5vw;
  width:12.6vw;
  display: inline-block;
  vertical-align: middle;
}

.headerMenu{
  display: inline-block;
  vertical-align: middle;
  margin-left: 7vw;
  margin-top: 7px;
}

.headerMenuItem{
  display: inline-block;
  vertical-align: middle;
  margin-left: 3vw;
  color:#353535;
  font-size: 0.9rem;
  font-family: GothamPro, sans-serif;
  text-decoration: none;
}
.headerMenuItem:hover{
  cursor: pointer;
  opacity:0.8;
}

.headerButtons{
  flex:1;
  margin-left: 4vw;
  margin-right: 4vw;
  text-align: right;
  align-items: flex-end;
  margin-top: 4px;
}

.headerButtonGrey{
  display: inline-block;
  vertical-align: middle;
  margin-right: 2vw;
  padding: 5px;
  color:#353535;
  font-size: 0.9rem;
  font-family: GothamPro, sans-serif;
}
.headerButtonGrey:hover{
  cursor: pointer;
  opacity:0.8;
}

.headerButtonBlue{
  display: inline-block;
  vertical-align: middle;
  padding:8px;
  padding-left: 18px;
  padding-right:18px;
  border-radius: 100px;
  color:white;
  background: #2e51ff;
  font-size: 0.9rem;
  font-family: "Comfortaa", sans-serif;
}
.headerButtonBlue:hover{
  cursor: pointer;
  opacity:0.8;
  background:#32e6d7;
}

.signupButtonIcon{
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width:16px;
  margin-top: -3px;
}

.landingContainer{
  margin-top: 60px;
  display: flex;
  flex-direction: row;
}

.landingText{
  flex:1;
  height:80vh;
  padding-left: 6vw;
}

.landingTextContent{
  position: relative;
  top:50%;
  transform: translate(0%,-50%);
  width:80%;
  font-family: "Nunito", sans-serif;
  font-size: 1.4rem;
  color:#686868;
}

.landingTextHeader{
  font-family: GothamPro, sans-serif;
  font-size: 2.2rem;
  color:#272727;
  margin-bottom: 2vh;
  width:80%;
}

.landingImageContainer{
  flex:1;
  height:80vh;
  text-align: center;
  align-items: center;
}

@keyframes landingImageFade {
  from {opacity:0}
  to {opacity:1}
}


.landingImage{
  display: inline-block;
  vertical-align: middle;
  width:40vw;
  position: relative;
  top:52%;
  transform: translate(0%,-50%);
  opacity:1;
  animation-name: landingImageFade;
  animation-duration: 1.2s;
}

.circle{
}

.waveFooter{
  z-index:20;
  position: relative;
  width:100%;
  opacity:0.7;
}

.benefitsContainer{
  padding-top: 80px;
  padding-bottom: 60px;
  text-align: center;
  align-items: center;
}

.benefit{
  position: relative;
  width:26%;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 3vh;
  padding-top: 3vh;
  padding-bottom: 1vh;
  border-radius:18px;
  background:#2e51ff;
  display: inline-block;
  vertical-align: middle;
  text-align:left;
  height:17vw;
  overflow: hidden;
}
.benefit:hover{
  background:#5d24f0;
}

.benefitBar{
  width:8px;
  height:100%;
  position: absolute;
  top:0px;
  left:0px;
  background: linear-gradient(180deg,#2aeef5,#00000000);
}

.benefitIcon{
  margin-left: 1.5vw;
  width:30px;
  align-self: flex-start;
  display: inline-block;
  vertical-align: middle;
}

.benefitTitle{
  color:white;
  margin-left: 0.8vw;
  font-size: 1rem;
  font-family: "GothamPro", sans-serif;
  display: inline-block;
  vertical-align: middle;
}

.benefitText{
  color:white;
  margin-left: 1.5vw;
  margin-right: 2vw;
  margin-top: 3vh;
  font-size: 1.1rem;
  font-family: "Nunito", sans-serif;
}

.signupButtonBlue{
  display: inline-block;
  margin-top: 30px;
  vertical-align: middle;
  padding:8px;
  padding-left: 32px;
  padding-right:32px;
  border-radius: 100px;
  color:white;
  background: #2e51ff;
  font-size: 1rem;
  font-family: "Comfortaa", sans-serif;
}
.signupButtonBlue:hover{
  cursor: pointer;
  opacity:0.8;
  background:#32e6d7;
}

.signupButtonBlueIcon{
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width:18px;
  margin-top: -3px;
}

.reviewContainer{
  padding-top:8vh;
  padding-bottom:8vh;
  background:#c4b3ff48;
  margin-top:3vh;
  text-align: center;
  align-items: center;
  width:100%;
}

.review{
  border-radius: 16px;
  width:60%;
  height:30vw;
  background-image: url('../../images/review-bg.png');
  background-position: center;
  background-size: cover;
  position: relative;
  left:20%;
}

.reviewScreenshot{
  width:42vw;
  position: absolute;
  left:-12vw;
  top:-6%;
  border-radius:12px;
  box-shadow: 0px 2px 12px rgba(10,0,180,0.4);
}

.reviewContent{
  width:35vw;
  height:30vw;
  position: absolute;
  right:-12vw;
  bottom:-7%;
  border-radius:18px;
  background: white;
  border:0px solid #4e4dff;
  box-shadow: 0px 2px 12px rgba(10,0,180,0.4);
}

.reviewText{
  padding:2vw;
  padding-top: 1vw;
  color:#989898;
  font-size: 1.1rem;
  font-family: "Nunito", sans-serif;
}

@keyframes starsFade {
  0% {opacity:0.5}
  50% {opacity:1}
  100% {opacity:0.5}
}


.reviewStars{
  width:8vw;
  margin-bottom: 10px;
  margin-top: 20px;
  animation-name: starsFade;
  animation-duration: 2.2s;
  animation-iteration-count: infinite;
}

.reviewSender{
  color:#2e51ff;
  margin-top: 1.8vw;
  font-size: 1rem;
  font-family: "GothamPro", sans-serif;
}

.reviewImage{
  width:4vw;
  margin-top: 2vw;
  border-radius: 200px;
  border:2px solid #2e51ff;;
}

.pricingContainer{
  width:100%;
  padding-top: 10vh;
  padding-bottom: 10vh;
  display:flex;
  flex-direction: row;
}

.pricingText{
  flex:1;
  margin-right: 8vw;
  margin-left: 10vw;
  box-shadow: 0px 2px 12px rgba(10,0,180,0.2);
  border-radius: 22px;
  padding-top:4vh;
  padding-bottom: 2vh;
}

.pricingTextHeader{
  padding-left:2vw;
  font-size: 1.6rem;
  font-family: "GothamPro", sans-serif;
  color:#484848;
}

.pricingTextSubheader{
  padding-left:2vw;
  font-size: 1.2rem;
  margin-top: 1.5vh;
  font-family: "GothamPro", sans-serif;
  color:#989898;
}

.pricingImageContainer{
  flex:1;
}

.pricingImage{
  width:80%;
  position: relative;
  left:5%;
}

.pricingPoint{
  padding-left: 2vw;
  margin-top: 2.2vh;
  font-size: 1rem;
  font-family: "Comfortaa", sans-serif;
  color:#989898;
}

.pricingPointIcon{
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.4vw;
  width:22px;
}

.contactContainer{
  width:calc(100% + 4px);
  margin-left: -2px;
  padding-top: 8vh;
  padding-bottom: 8vh;
  display:flex;
  flex-direction: row;
  background-image: url('../../images/review-bg.png');
  background-position: center;
  background-size: cover;
  position: relative;
  text-align: center;
}

.contactForm{
  flex:1;
  margin-right: 20vw;
  margin-left: 20vw;
  box-shadow: 0px 2px 12px rgba(10,0,180,0.2);
  border-radius: 22px;
  padding-top:4vh;
  padding-bottom: 8vh;
  background: white;
}

.contactHeader{
  padding-left:2vw;
  font-size: 1.4rem;
  font-family: "GothamPro", sans-serif;
  color:#484848;
}

.contactSubheader{
  padding-left:2vw;
  font-size: 1.2rem;
  margin-top: 1.5vh;
  font-family: "GothamPro", sans-serif;
  color:#989898;
}

.contactInput{
  width:60%;
  border-radius: 16px;
  margin-top: 40px;
  padding:2vh;
  border:1px solid #787878;
  font-family: "Comfortaa", sans-serif;
  color:#787878;
}
.contactInput:focus{
  outline: none;
  border:1px solid #2e51ff;
}

.contactInputMessage{
  min-width:60%;
  max-width:60%;
  min-height:16vh;
  max-height:16vh;
  border-radius: 16px;
  margin-top: 40px;
  padding:2vh;
  border:1px solid #787878;
  font-family: "Comfortaa", sans-serif;
  color:#787878;
}
.contactInputMessage:focus{
  outline: none;
  border:1px solid #2e51ff;
}

.submitButtonBlue{
  display: inline-block;
  margin-top: 30px;
  vertical-align: middle;
  padding:8px;
  padding-left: 32px;
  padding-right:32px;
  border-radius: 100px;
  color:white;
  background: #2e51ff;
  font-size: 1rem;
  font-family: "Comfortaa", sans-serif;
}
.submitButtonBlue:hover{
  cursor: pointer;
  opacity:0.8;
  background:#32e6d7;
}

.submitButtonBlueIcon{
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width:18px;
  margin-top: -3px;
}

.figuresContainer{
  width:100%;
  padding-top: 3vh;
  padding-bottom: 3vh;
  display:flex;
  flex-direction: row;
  border-top: 1px solid #BCBCBC;
}

.figure{
  text-align: center;
  padding-left: 5vw;
  padding-right: 5vw;
  flex:1;
  font-size: 0.9rem;
  margin-top: 1.5vh;
  font-family: "GothamPro", sans-serif;
  color:#989898;

}

.figureHeader{
  font-size: 1.2rem;
  font-family: "GothamPro", sans-serif;
  color:#484848;
  margin-bottom: 10px;
}

.footer{
  margin-top: 0vh;
  border-top: 1px solid #2e51ff;
  padding-top: 5vh;
  padding-bottom: 8vh;
  display: flex;
  flex-direction: row;
}

.footerPortion{
  flex:1;
  padding-left: 4vw;
  padding-right: 4vw;
}

.footerPortionFlex{
  flex:1;
  padding-left: 4vw;
  padding-right: 4vw;
  display: flex;
  flex-direction: column;
}

.footerLogo{
  width:80%;
  margin-top: 2vh;
}

.footerLink{
  color: #2e51ff;
  font-size: 1rem;
  font-family: "Nunito", sans-serif;
  margin-top: 2vh;
  text-decoration: none;
}
.footerLink:hover{
  cursor: pointer;
  opacity:0.7;
}

.footerCopyright{
  color: #383838;
  font-size: 1rem;
  font-family: "Comfortaa", sans-serif;
}

.flexPadding{
  flex:1;
}

/*
 ************************************************
  MOBILE CSS
 ************************************************
*/




@media only screen and (max-width: 768px) {
  .globalContainer{
    width:100%;
    height:100%;
    position: absolute;
    top:0px;
    left:0px;
    background:red;
  }

  .roiCalculatorHeaderLogo{
    height:4.4vh;
    display: block;
    position: relative;
    left:50%;
    transform: translate(-50%,0%);
    margin-bottom: 20px;
  }

  .savingAmount{
    margin-top: 8vh;
    color:#686868;
    font-size: 1.5rem;
    font-family: "GothamPro", sans-serif;
    padding-bottom: 10vh;
    padding-left: 3vw;
    padding-right: 3vw;
  }

  .trustpilot{
    width:24vw;
    margin-left: 2vw;
    margin-top: 2vh;
    margin-bottom: 1vh;
  }


  .headerLogo{
    margin-left: 4vw;
    height:10vw;
    width:30vw;
    display: inline-block;
    vertical-align: middle;
  }

  .headerMenu{
    display: none;
  }

  .landingContainer{
    margin-top: 60px;
    display: flex;
    flex-direction: row;
  }

  .landingText{
    flex:1;
    height:80vh;
    padding-left: 6vw;
  }

  .landingTextContent{
    position: relative;
    top:50%;
    transform: translate(0%,-50%);
    width:90%;
    font-family: "Nunito", sans-serif;
    font-size: 1.4rem;
    color:#686868;
  }

  .landingText2{
    flex:1;
    height:80vh;
    padding-left: 6vw;
  }

  .landingTextContent2{
    position: relative;
    top:50%;
    transform: translate(0%,-50%);
    width:80%;
    font-family: "Nunito", sans-serif;
    font-size: 1.4rem;
    color:#686868;
  }

  .landingTextContentH{
    font-family: "Nunito", sans-serif;
    font-size: 1.4rem;
    color:#686868;
  }


  .landingTextHeader{
    font-family: GothamPro, sans-serif;
    font-size: 2rem;
    color:#272727;
    margin-bottom: 2vh;
    width:100%;
  }

  .landingTextHeader2{
    font-family: GothamPro, sans-serif;
    font-size: 2.2rem;
    color:#272727;
    margin-bottom: 2vh;
    width:100%;
  }

  .landingImageContainer{
    display:none;
  }

  @keyframes landingImageFade {
    from {opacity:0}
    to {opacity:1}
  }


  .landingImage{
    display: none;
  }

  .companiesBanner{
    margin-top: 9vh;
    width:99%;
    position: relative;
    left:0.5%;
  }

  .roiCalculator{
    flex:1;
    margin-right: 5vw;
    margin-left: 5vw;
    box-shadow: 0px 2px 12px rgba(10,0,180,0.2);
    border-radius: 22px;
    padding-top:0vh;
    padding-bottom: 2vh;
    overflow:hidden;
  }

  .benefitsContainer{
    padding-top: 20px;
    padding-bottom: 60px;
    text-align: center;
    align-items: center;
  }

  .benefit{
    position: relative;
    width:90%;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 3vh;
    padding-top: 3vh;
    padding-bottom: 1vh;
    border-radius:18px;
    background:#2e51ff;
    display: inline-block;
    vertical-align: middle;
    text-align:left;
    height:34vw;
    overflow: hidden;
  }
  .benefit:hover{
    background:#5d24f0;
  }

  .benefit:hover .benefitIcon{
    width:34px;
  }

  .benefit:hover .benefitTitle{
    font-size: 1.1rem;
  }

  .benefitBar{
    width:8px;
    height:100%;
    position: absolute;
    top:0px;
    left:0px;
    background: linear-gradient(180deg,#2aeef5,#00000000);
  }

  .benefitIcon{
    margin-left: 3vw;
    width:30px;
    align-self: flex-start;
    display: inline-block;
    vertical-align: middle;
  }

  .benefitTitle{
    color:white;
    margin-left: 1.6vw;
    font-size: 1rem;
    font-family: "GothamPro", sans-serif;
    display: inline-block;
    vertical-align: middle;
  }

  .benefitText{
    color:white;
    margin-left: 3vw;
    margin-right: 2vw;
    margin-top: 3vh;
    font-size: 1.1rem;
    font-family: "Nunito", sans-serif;
  }

  .landingText2{
    flex:1;
    height:80vh;
    padding-left: 6vw;
  }

  .landingTextContent2{
    position: relative;
    top:50%;
    transform: translate(0%,-50%);
    width:90%;
    font-family: "Nunito", sans-serif;
    font-size: 1.4rem;
    color:#686868;
  }

  .pricingContainer{
    width:100%;
    padding-top: 10vh;
    padding-bottom: 10vh;
    display:inline-block;
  }

  .pricingText{
    width:90%;
    margin-right: 5vw;
    margin-left: 5vw;
    box-shadow: 0px 2px 12px rgba(10,0,180,0.2);
    border-radius: 22px;
    padding-top:4vh;
    padding-bottom: 2vh;
  }

  .pricingTextHeader{
    padding-left:2vw;
    font-size: 1.6rem;
    font-family: "GothamPro", sans-serif;
    color:#484848;
  }

  .pricingTextSubheader{
    padding-left:2vw;
    font-size: 1.2rem;
    margin-top: 1.5vh;
    font-family: "GothamPro", sans-serif;
    color:#989898;
  }

  .pricingImageContainer{
    width:100%;
  }

  .pricingImage{
    margin-top: 6vh;
    width:80%;
    position: relative;
    left:10%;
  }

  .pricingPoint{
    padding-left: 2vw;
    margin-top: 2.2vh;
    font-size: 1rem;
    font-family: "Comfortaa", sans-serif;
    color:#989898;
  }

  .reviewContainer{
    padding-top:8vh;
    padding-bottom:8vh;
    background:#c4b3ff48;
    margin-top:3vh;
    text-align: center;
    align-items: center;
    width:100%;
  }

  .review{
    border-radius: 16px;
    width:100%;
    height:80vw;
    text-align: center;
    position: relative;
    left:0%;
  }

  .reviewScreenshot{
    display:none;
  }

  .reviewContent{
    width:80%;
    position: relative;
    left:10%;
    height:70vw;
    border-radius:18px;
    background: white;
    border:0px solid #4e4dff;
    box-shadow: 0px 2px 12px rgba(10,0,180,0.4);
  }

  .reviewText{
    padding:2vw;
    padding-top: 1vw;
    color:#989898;
    font-size: 1.1rem;
    font-family: "Nunito", sans-serif;
  }

  .contactForm{
    flex:1;
    margin-right: 6vw;
    margin-left: 6vw;
    box-shadow: 0px 2px 12px rgba(10,0,180,0.2);
    border-radius: 22px;
    padding-top:4vh;
    padding-bottom: 8vh;
    background: white;
  }


  .contactInput{
    width:80%;
    border-radius: 16px;
    margin-top: 40px;
    padding:2vh;
    border:1px solid #787878;
    font-family: "Comfortaa", sans-serif;
    color:#787878;
  }
  .contactInput:focus{
    outline: none;
    border:1px solid #2e51ff;
  }

  .contactInputMessage{
    min-width:80%;
    max-width:80%;
    min-height:16vh;
    max-height:16vh;
    border-radius: 16px;
    margin-top: 40px;
    padding:2vh;
    border:1px solid #787878;
    font-family: "Comfortaa", sans-serif;
    color:#787878;
  }
  .contactInputMessage:focus{
    outline: none;
    border:1px solid #2e51ff;
  }

  .reviewImage{
    width:9vw;
    margin-top: 5vw;
    border-radius: 200px;
    border:2px solid #2e51ff;;
  }

  @keyframes starsFade {
    0% {opacity:0.5}
    50% {opacity:1}
    100% {opacity:0.5}
  }


  .reviewStars{
    width:18vw;
    margin-bottom: 10px;
    margin-top: 20px;
    animation-name: starsFade;
    animation-duration: 2.2s;
    animation-iteration-count: infinite;
  }

  .figuresContainer{
    width:100%;
    padding-top: 3vh;
    padding-bottom: 6vh;
    display:inline-block;
  }

  .figure{
    text-align: center;
    padding-left: 5vw;
    padding-right: 5vw;
    flex:1;
    font-size: 1.2rem;
    margin-top: 4vh;
    margin-bottom: 4vh;
    font-family: "GothamPro", sans-serif;
    color:#989898;
    width:40%;
    display: inline-block;
    vertical-align: middle;
  }

  .figureHeader{
    font-size: 1.9rem;
    font-family: "GothamPro", sans-serif;
    color:#484848;
    margin-bottom: 10px;
  }

  .footer{
    margin-top: 0vh;
    border-top: 0px solid #2e51ff;
    padding-top: 5vh;
    padding-bottom: 8vh;
    display: inline-block;
  }

  .footerPortion{
    width:50%;
    padding-left: 4vw;
    padding-right: 4vw;
    margin-bottom: 3vh;
  }

  .footerPortionFlex{
    flex:1;
    padding-left: 4vw;
    padding-right: 4vw;
    display: flex;
    flex-direction: column;
  }

  .footerLogo{
    width:100%;
    margin-top: 2vh;
  }

  .footerLink{
    color: #2e51ff;
    font-size: 1rem;
    font-family: "Nunito", sans-serif;
    margin-top: 2vh;
    text-decoration: none;
  }
  .footerLink:hover{
    cursor: pointer;
    opacity:0.7;
    text-decoration: none;
  }

  .footerCopyright{
    color: #383838;
    font-size: 1rem;
    margin-top: 4vh;
    font-family: "Comfortaa", sans-serif;
  }

  .headerButtonBlue{
    display:none;
  }

  .headerButtonBlueMenu{
    display: inline-block;
    vertical-align: middle;
    padding:8px;
    padding-left: 18px;
    padding-right:18px;
    border-radius: 100px;
    color:white;
    background: #2e51ff;
    font-size: 0.9rem;
    font-family: "Comfortaa", sans-serif;
  }
  .headerButtonBlueMenu:hover{
    cursor: pointer;
    opacity:0.8;
    background:#32e6d7;
  }

  @keyframes mobileMenuAnimation {
    from {right:-70vw;}
    to {right:0vw;}
  }

  .mobileMenu{
    width:70%;
    box-shadow: -10px 8px 14px 0px rgba(10,0,180,0.2);
    background:white;
    position: fixed;
    right:0px;
    top:0px;
    height:100%;
    z-index:200;
    animation-name: mobileMenuAnimation;
    animation-duration: 0.5s;
  }

  .menuXButton{
    margin-top: 20px;
    margin-left: 20px;
    width:26px;
  }

  .menuItems{
    padding-top:5vh;
    padding-left: 3vw;
  }

  .menuItem{
      margin-top:4vh;
      color:#353535;
      font-size: 1.2rem;
      font-family: GothamPro, sans-serif;
      text-decoration: none;
  }

  .dropdownMenuItem{
    color:#2e51ff;
    font-family: "GothamPro", sans-serif;
    font-size: 0.9rem;
    padding-top: 1.4vh;
    padding-bottom: 1.2vh;
    margin-left: 2vw;
  }


}

.dropdownIcon{
  display: inline-block;
  vertical-align: middle;
  width:18px;
  margin-right: 12px;
}
