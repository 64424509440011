
.sideMenuItemMain{
  border-radius:12px;
  padding:30px;
  padding-left:10px;
  font-family: "Open Sans", sans;
  font-weight: 600;
  font-size: 0.8rem;
  padding-top: 1.2vh;
  padding-bottom: 1.2vh;
  width:98%;
  margin-top: 1.4vh;
  border:0px;
  color:#333333;
  text-align: left;
  opacity:0.8;
}
.sideMenuItemMain:hover{
  cursor:pointer;
  opacity:0.4;
}

.sideMenuItemMainActive{
  border-radius:12px;
  padding:30px;
  padding-left:10px;
  font-family: "Open Sans", sans;
  font-weight: 600;
  font-size: 0.8rem;
  padding-top: 0.8vh;
  padding-bottom: 0.8vh;
  margin-right: 0px;
  margin-top: 1.4vh;
  border:0px;
  color:#3177dd;
  text-align: left;
  background:#5199ff22;
  opacity:0.8;
}
.sideMenuItemMainActive:hover{
  cursor:pointer;
  opacity:0.4;
}

.sideMenuItemActiveMainBar{
  display:none;
}

.sideMenuItemActiveMainBarActive{
  display: inline-block;
  vertical-align: middle;
  width:4px;
  border-top-right-radius:4px;
  border-bottom-right-radius:4px;
  background:#3300D4;
  height:20px;
  margin-right: 5px;
}

.sideMenuItemTextMain{
  vertical-align: middle;
  display: inline-block;
}

.sideMenuItemIcon{
  width:1.2vw;
  vertical-align: middle;
  display: inline-block;
  margin-right: 1.4vw;
}
